module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 550px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:200,400,700,900"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://seaconnect.es/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang?/contacto","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/contact","getLanguageFromPath":true,"excludeLanguages":["es"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sea Connect - Sitio web oficial","short_name":"Seaconnect","description":"Sistemas de Comunicación, AVIT, Navegación y Energía. VSAT, TV, 4G, GMDSS, CCTV, Inspecciones de radio. Ingenieros con base en Palma ¡Contáctanos!","lang":"es","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#1C4374","theme_color":"#1C4374","localize":[{"start_url":"/en/","lang":"en","name":"Seaconnect - Oficial Website","short_name":"Seaconnect","description":"Communication Systems, AVIT, Navigation and Energy. VSAT, TV, 4G, GMDSS, CCTV, Radio inspections. Engineers based in Palma Contact us!"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"90225eca20f4e070b5f4bd639fe66313"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://seaconnect.es/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
